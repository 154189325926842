import React, { Component } from 'react';
 


class Header extends Component {

    render() {
        return (
            <div>
                <img src="/MG_Web_Logo.png"  width="90%" alt="Marana Group Logo" />
            </div>
      )
    }

}

export default Header;