import React, { Component } from 'react';
 
import { Button, FormGroup, ControlLabel, FormControl, Grid, Row, Col, Badge } from "react-bootstrap";
 
 
 
 
 

export class VehicleAddIssue extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
            notes: "",
            showMe: false,    
            }



    }

 
    handleSubmit = (event) => {

        //TODO- pull log id from State Consumer
        event.preventDefault();

        if (this.state.notes.length === 0  
        ) {
            alert("Please enter issue description or select Cancel to close");
            return;
        }

 

        let param = JSON.stringify({
            "logID": this.props.routeLogID,
            "notes": this.state.notes
        });

        //   props.onClose;

        fetch('api/LmmiRoute/AddIssue',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: param,
            })
            .then(response => response.json())
            .then(data => {
                this.props.parentCallback(data);
                this.setState({ showMe: false });
            });


    }
    handleChange = (event) => {

        this.setState({
            [event.target.id]: event.target.value,
        });
    }



    showVehicleIssueOption(event) {
        this.setState({ showMe: true, notes:"" })
    }
    render() {

        if (!this.state.showMe) {
            return (
                <div>
                    <Button
                        block
                        bsClass="mg_button_block  center-block"
                        onClick={() => { this.showVehicleIssueOption() }} > Report Van Issue  <Badge variant="light" className="center-right"> {this.props.badgeCount}</Badge></ Button >
                </div>
            );
        } else {

            return (
                <div>


                    <p className="h4 text-center mg_green_font">
                        Report Van Issue
                   </p>


                    <form onSubmit={this.handleSubmit}>

                        <FormGroup controlId="notes">
                            <ControlLabel>Issue Description</ControlLabel>
                            <FormControl
                                id="notes"
                                componentClass="textarea"
                                rows="5"
                                label="Notes"
                                placeholder="Please describe issue"
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                        <Grid>
                        <Row>
                            <Col xs={6} md={8}>



                                <Button className="mg_button pull-left" type="submit">Save and close</Button>
                            </Col>
                            <Col xs={6} md={8}>
                                <Button className="mg_button pull-right"   onClick={() => { this.setState({ showMe: false }) }}>Cancel</Button>
                            </Col>
                        </Row>
                        </Grid>
 

                    </form>
                  
                </div>

            );
        }
    }
}