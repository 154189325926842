import React, { Component } from 'react'
import QrReader from 'react-qr-reader'

export class QRScanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            delay: 500 
        }

        this.handleScan = this.handleScan.bind(this)
    }


    handleScan=(result)=> {
            this.props.parentCallback(result,null);
       
    }


    handleError=(err)=> {
        if (err) {
            this.props.parentCallback(null, err);
           
        }
    }

    render() {
        const previewStyle = {
            height: 200,
            width: 200,
        }

        return (
            <div>
                <p className="h5 text-center mg_blue_font">Please scan QR Code on placard</p>

                <div className="center-block">
                <QrReader
                    delay={this.state.delay}
                    style={previewStyle}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    />
                    </div>
              
            </div>
        )
    }
}