import React, { Component, Fragment } from 'react';
import { Button, FormGroup, ControlLabel, FormControl, Grid, Row, Col, Badge } from "react-bootstrap";
 
 

export class VehicleAddFuel extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
                fuelQuantity: "",
                fuelCost: "",
                showMe: false,
                odometer: 0,
                notes: "",
            badgeCount: 0,
            requireFuelCost:false
            }



    }




    handleSubmit = (event) => {

        //TODO- pull log id from State Consumer
        event.preventDefault();
        console.log(this.state.odometer.length === 0);

        if (this.state.fuelQuantity.length === 0) {
            alert("Please enter fuel quantity");
            return;
        }

        if (this.state.odometer.length === 0 || this.state.odometer === 0) {
            alert("Please enter odometer reading")
            return;
        }
        
        //are values numbers?
        if (isNaN(this.state.fuelQuantity)) {
            alert("Fuel quantity should be a number");
            return;
        }
        if (this.state.fuelCost.length !== 0 && isNaN(this.state.fuelCost)) {
            alert("Fuel cost should be a number");
            return;
        }

        if( isNaN(this.state.odometer) ) {
            alert("Odometer reading should be a number");
            return;
        }

        //are values reasonable length

        if (this.state.fuelQuantity.length>4) {
                alert("Fuel quantity value is too long");
                return;
            }
        if (this.state.fuelCost.length>7) {
                alert("Fuel cost value is too long");
                return;
        }

         if (this.state.odometer.length>7) {
                alert("Odometer reading is too long");
                return;
         }
            
        let param = JSON.stringify({
            "logID": this.props.routeLogID,
            "fuelType": this.inputFuelType.value,
            "quantity": this.state.fuelQuantity,
            "cost": this.state.fuelCost,
            "odometer": this.state.odometer,
            "notes":this.state.notes
        });

     //   props.onClose;
       
        fetch('api/LmmiRoute/AddFuel',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: param,
            })
            .then(response => response.json())
            .then(data => {
                this.props.parentCallback(data);
                this.setState({ showMe: false });                
            });
           

    } 
    handleChange = (event) => {
 
        this.setState({
            [event.target.id]: event.target.value,
        });
    }

    setFuelCostState = () => {
        console.log(this.inputFuelType);
        let requireCostFlag = false;
        if (this.inputFuelType === undefined) {
            requireCostFlag= true;
        }
        if (this.inputFuelType.value === "Propane") {
            requireCostFlag= true;
        }
        this.setState({ requireFuelCost: requireCostFlag });
    }

 
    showFuelSection=(event)=> {
        this.setState({
            showMe: true,
            fuelQuantity: "",
            fuelCost: "",
            odometer: 0,
            notes: ""
        })
    }

 
    render() {

                if (!this.state.showMe) {
            return (
                <div>
                    
                        <Button
                        block
                        bsClass="mg_button_block  center-block"
                        onClick={() => { this.showFuelSection() }} > Add Fuel   <Badge variant="light" className="center-right"> {this.props.badgeCount} </Badge>


                        </ Button >
                </div>
            );
        } else {

                   
            return (
                <div>



                    <p className="h4 text-center mg_green_font">
                        Record Fuel Addition
               </p>

                    <p className="text-center"><em> Propane usage is preferred due to cost savings </em></p>


                    
                    <form onSubmit={this.handleSubmit}>
                        <Grid>
                            <Row>
                                <Col xs={6} md={8}>
                                <FormGroup controlId="fuelType">
                                    <ControlLabel>Fuel Type</ControlLabel>
                                        <FormControl as="select" componentClass="select" placeholder="select" onChange={this.setFuelCostState} inputRef={el => this.inputFuelType = el} >
                                            {(this.props.fuel==="Propane")
                                                ? <Fragment><option value="Propane">Propane</option> <option value="Gasoline">Gasoline</option></Fragment> 
                                                : <option value="Gasoline">Gasoline</option> 
                                            }

                                           
                                        </FormControl>
                                </FormGroup>
                                </Col>
                                <Col xs={6} md={8}>
                        <FormGroup controlId="fuelQuantity">
                        <ControlLabel>Fuel Quantity</ControlLabel>
                        <FormControl
                            id="fuelQuantity"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            label="Fuel Quantity"
                                            step="0.01"
                            placeholder="Enter quantity"
                            onChange={this.handleChange}
                        />
                        </FormGroup>
                                </Col>
                                </Row>
                            

                            <Row>
                                <Col xs={6} md={8}>
                        <FormGroup controlId="fuelCost">
                        <ControlLabel>Fuel Cost</ControlLabel>
                                        <FormControl
                                            id="fuelCost"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            step="0.01"
                                            label="Fuel Cost"
                                            placeholder="Enter price paid"
                                            onChange={this.handleChange}
                                            disabled={this.state.requireFuelCost}
                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} md={8}>
                        <FormGroup controlId="odometer">
                            <ControlLabel>Odometer</ControlLabel>
                            <FormControl
                                id="odometer"
                                            type="number"
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                label="Odometer"
                                placeholder="Enter odometer"
                                onChange={this.handleChange}
                            />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                             
                        <FormGroup controlId="notes">
                            <ControlLabel>Notes</ControlLabel>
                            <FormControl
                                id="notes"
                                type="textarea"
                                label="Notes"
                                placeholder="Enter notes (optional)"
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                            </Row>
                           

                            <Row>
                                <Col xs={6} md={8}>



                                    <Button className="mg_button pull-left" type="submit">Save and close</Button>
                                </Col>
                                <Col xs={6} md={8}>
                                    <Button className="mg_button pull-right"  onClick={() => { this.setState({ showMe: false }) }}>Cancel</Button>
                                </Col>
                                </Row>
                        </Grid>
                        </form>
                    <br />
                    <br />

                    
                </div>

            );
        }
    }
}