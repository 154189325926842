import React, { Component } from 'react';
import { VehicleAddIssue } from './VehicleAddIssue.jsx';
import { VehicleAddFuel } from './VehicleAddFuel.jsx';
import { Button, FormGroup, ControlLabel, FormControl, Table } from "react-bootstrap";

 

export class CloseRoute extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
            routeStats: "",
            odometer: "",
            fuelLevelPropane: "",
            fuelLevelGasoline: "",
            logRouteID: this.props.routeLogId,
            showOdometerWarning: true,
            initialOdometer: this.props.initialOdometer,
            }

        this.fetchRouteStats();
    }

     
    fetchRouteStats=()=> {
        console.log("got id in fetch");
        console.log(this.state.logRouteID);
        fetch(`api/LmmiRoute/GetRouteStats?routeLogId=${this.state.logRouteID}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            })
            .then(response => response.json())
            .then(data => {
                this.setState({ routeStats: data })
                    
                    });
                

    }
 
    handleFieldUpdate = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });

    } 

    updateFuelRecord = (id) => {
        this.fetchRouteStats();
    }


    handleSubmit = (event) => {

        event.preventDefault();

        


        if (this.state.odometer.length === 0) {
            alert("Odometer reading is required");
            return;
        }
        if (isNaN(this.state.odometer)) {
            alert("Odometer reading should be a number");
            return;
        }

        if (this.state.odometer.length > 7) {
            alert("Odometer reading is too long");
            return;
        }

        if (this.state.odometer - this.state.initialOdometer < 0) {
            alert("Odometer reading at the end of the route cannot be less then reading at the start of the route. Please reenter.");
            try {
                document.getElementById('odometer').value = "";
            } catch (e) { }
           
            return;
        }



        if (this.state.showOdometerWarning && (this.state.odometer - this.state.initialOdometer) >250) {
            alert("Based on your odometer entry you've driven " + (this.state.odometer - this.state.initialOdometer) + " miles this route. Please reenter to confirm");
            this.setState({ showOdometerWarning :false });
            try {
                document.getElementById('odometer').value = "";
            } catch (e) { }
            return;
        }


        if (this.inputGasolineLevel.value.length === 0) {
            alert("Gasoline level value is required");
            return;
        }

        if (this.state.routeStats.fuel === "Propane") {
            if (this.inputPropaneLevel.value.length === 0) {
                alert("Propane level value is required");
                return;
            }
        }
        

        
        let json = JSON.stringify({
            "odometer": this.state.odometer,
            "fuelGasoline": this.inputGasolineLevel.value,
            "fuelPropane": (this.state.routeStats.fuel==="Propane")?this.inputPropaneLevel.value:"0",
            "logID": this.state.logRouteID
        })
 
        fetch("api/LmmiRoute/CloseRoute",
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: json,
            })
            .then(response => response.json())
            .then(data => {
                //remove authentication and redirect to login
                this.props.parentCallback("OK");

            });
            

    }

    loadStats = (routeStats) => {
        return (
            <div>
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Stops completed:</td>
                            <td>{routeStats.stopsCompleted}</td>
                        </tr>
                        <tr>
                            <td> Fuel Added (Gasoline):</td>
                            <td>{routeStats.fuelAddedGasoline}</td>
                        </tr>
                        {(this.state.routeStats.fuel === "Propane") ?
                            <tr>
                                <td>Fuel Added (Propane):</td>
                                <td>{routeStats.fuelAddedPropane}</td>
                            </tr> : <tr />}
                    </tbody>
                </Table>
                <VehicleAddFuel routeLogID={this.state.logRouteID} badgeCount={routeStats.fuelAddedGasoline + routeStats.fuelAddedPropane}
                    parentCallback={this.fetchRouteStats} fuel={this.state.routeStats.fuel}/> 

                {<VehicleAddIssue routeLogID={this.state.logRouteID} badgeCount={routeStats.issuesReported}
                    parentCallback={this.fetchRouteStats} />}
     
            </div>

            
        );
    }
    
    render() {

        let routeContent = this.state.routeStats === "" ? "Loading" : this.loadStats(this.state.routeStats);

        return (
            <div>
                <p className="h3 text-center mg_blue_font"> Route is finished.</p>
                <p className="h4 text-center"><em> Please enter required values to close</em></p>
                {routeContent}

                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="odometer" bsSize="large">
                        <ControlLabel>Odometer Reading</ControlLabel>
                        <FormControl
                            autoFocus
                            type="number"
                            ref="odometerReadingRef"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            onChange={this.handleFieldUpdate}
                        />
                    </FormGroup>

                    <FormGroup controlId="fuelLevelGasoline" bsSize="large">
                        <ControlLabel>Remaining Fuel Level (Gasoline)</ControlLabel>
      
                        <FormControl as="select" componentClass="select" placeholder="select" inputRef={el => this.inputGasolineLevel = el} >
                            <option value=""></option>
                            <option value="0.25">Quarter tank</option>
                            <option value="0.5">Half a tank</option>
                            <option value="0.75">Three quarters of a tank</option>
                            <option value="1">Full tank</option>
                        </FormControl>


                    </FormGroup>
                    {(this.state.routeStats.fuel === "Propane") ?

                        <FormGroup controlId="fuelLevelPropane" bsSize="large">
                            <ControlLabel>Remaining Fuel Level (Propane)</ControlLabel>
                            <FormControl as="select" componentClass="select" placeholder="select" inputRef={el => this.inputPropaneLevel = el} >
                                <option value=""></option>
                                <option value="0.25">Quarter tank</option>
                                <option value="0.5">Half a tank</option>
                                <option value="0.75">Three quarters of a tank</option>
                                <option value="1">Full tank</option>
                            </FormControl>
                        </FormGroup>
                        : ""}
                    <Button
                        block
                        bsSize="large"
                        bsClass="mg_button_green center-block"
                        type="submit">
                        Close Route
                    </Button>

                </form>

    
                
            </div>

        );
    }
}