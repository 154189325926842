import React from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.version;


class AppCacheReset extends React.Component {

    refreshCacheAndReload= () => {
            console.log('Reload content')
            if (caches) {
                caches.keys().then(function (names) {
                    for (let name of names) caches.delete(name);
                });
            }
            window.location.reload(true);
        }
  

componentDidMount() {
    console.log("calling component did mount");
    fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
            console.log("returned from meta.json");
            console.log(meta.version);
            console.log("global version is ");
            console.log(global.appVersion);
            if (meta.version !== global.appVersion) {
                console.log("clearing cache");
                this.refreshCacheAndReload();
            } else {
                console.log("cache clearing is not needed");
            }
        })
        .catch(error => {
            console.log("error "+error);
        });

         
    }
    render() {
        return(
            <p></p>
        );
    }
}

export default AppCacheReset;