import React, { Component } from 'react';
import { Route } from 'react-router';
import AppCacheReset from './AppCacheReset';

import { Layout } from './components/Layout';
import { Home } from './components/Home';



export default class App extends Component {


  displayName = App.name

    render() {
            return (
  
                <Layout>
                    <AppCacheReset />
            <Route exact path='/' component={Home}  />
  
              </Layout>
        
    );
  }
  
}
