import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import packageJson from '../../package.json';
global.appVersion = packageJson.version;
 
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userID: "",
            password: "",
            authError: "",
 
        }
    }



 
    handleFieldUpdate = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
            authError: ""
        });

    }

    handleSubmit = (event) => {


        event.preventDefault();

        if (this.state.userID.length === 0 || this.state.password.length === 0) {
            this.setState({

                authError: "Please specify user id and password"
            })
            return;
        }
 
		fetch('api/Authentication/GetUser',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    }),
                    body: JSON.stringify({ "userID": this.state.userID, "password": this.state.password }),
            })
            .then(response => response.json())
            .then(data => {
    
                if (data) {
                    //send callback to parent
                      this.props.parentCallback(data);
                 

                } else {

                    console.log("bad credentials");
                    this.setState({
                
                        authError: "Your credentials are not valid"
                    })
                }
            })
            .catch(error => {
                console.log("error occured");
                console.log(error);
                this.setState({
                    authError: "Error occured on the server. Please notify your manager"
                })

            })
   
         

    }
    getAuthenticatedState(proMode) {
        return proMode ? "Authenticated" : "Not Authenticated";
    }
    getDriverID(id) {
        return id;
    }

    render() {

        return (
      

            <div className="centerVerticalPanel">
                <p className="h3 text-center mg-white">Please Login </p>
                <br />
                <br/>


                <form className="mg-margins" onSubmit={this.handleSubmit}>
                    <FormGroup  controlId="userID" bsSize="large">
                       
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.userID}
                            onChange={this.handleFieldUpdate}
                            placeholder="Username"
                        />
                    </FormGroup>


                    <FormGroup controlId="password" bsSize="large">
                       
                        <FormControl
                            type="password"
                            value={this.state.password}
                            onChange={this.handleFieldUpdate}
                            placeholder="Password"
                        />
                    </FormGroup>

                    <ControlLabel style={{ color: 'red' }}>{this.state.authError}</ControlLabel>
               
                    <Button
                        block
                        bsClass="large mg_button_green center-block"
                        type="submit">
                        Login
                    </Button>

                    <br />
                    <br/>
                    <div>
                        <em><p className="text-center mg-white">{global.appVersion} build 05/24/2022</p></em>
                        <em><p className="text-center mg-white">Copyright 2022 Marana Group</p></em>
                        </div>
                </form>
            </div>

        )
    }


}
export default Login;