import React, { Component, Fragment} from 'react';

import { Button,  FormControl, Table } from "react-bootstrap";
 
import { VehicleAddFuel } from './VehicleAddFuel.jsx';

 
 
export class ConfirmRoute extends Component {
    closeChild = () => {
        this.setState({
            showChild: false
        });
    };
    constructor(props) {
        super(props);
        this.state = {
            routeID: "",
            route: "",
            loading: true,
            confirmRouteError: "",
            showChild: false,
            fuelRecordID: [],
            routeLogRecord: "",
            vehicles: [],
            odometerCorrected: "",
            vehicleStats: "",
            specialStopSelected: "",
            specialStopIsRecurring:"N",
            odometerWarningShow:true
            
        }
        

        fetch(`api/LmmiRoute/GetDriverRoute?driverID=${this.props.user.associateNum}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            })
            .then(response => response.json())
            .then(data => {
                this.setState({ route: data, loading: false });
                if (data && data.routeId != null) {
                    console.log("calling fetch vehicle from initial load for " + data + "and " + data.vehicle);
                    this.fetchVehicleStats(data.vehicle);
                }
            });

        this.fetchAllVehicles();

    }

    fetchVehicleStats = (vehicleNum) => {
        fetch(`api/LmmiRoute/GetVehicleStats?vehicleNum=${vehicleNum}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            })
            .then(response => response.json())
            .then(data => {
                this.setState({ vehicleStats: data});
 
            });
    }

    fetchAllVehicles() {
        fetch(`api/LmmiRoute/GetAllVehicles`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            })
            .then(response => response.json())
            .then(data => {
                this.setState({ vehicles: data, loading: false });
            });
    }
    handleFieldUpdate = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });

    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (!isNaN(this.state.odometerCorrected) && this.state.odometerCorrected!=="" &&
            this.state.odometerCorrected < this.state.vehicleStats.odometerReading &&
            this.state.odometerWarningShow) {
            alert("You are entering odometer reading that is smaller than previously recorded. Please confirm that correct vehicle is selected in the vehicle drop down");
            this.refs.odometerReadingRef.value = '';
            this.setState({
                odometerWarningShow: false, 
            });
        } else {
            this.startRoute();
        }

    }

    handleOdometer = (event) => {
        if (!isNaN(event.target.value)) {
          
                this.setState({ odometerCorrected: event.target.value });
          
        }
    }

    startSpecialStopRoute = (event) => {
        if (this.state.overrideVehicle === undefined || this.state.overrideVehicle==="") {
            alert("Please select vehicle");
            return;
        }
        if (!isNaN(this.state.odometerCorrected) &&
            this.state.odometerCorrected.length > 0 &&
            this.state.odometerCorrected < this.state.vehicleStats.odometerReading &&
            this.state.odometerWarningShow) {
            alert("You are entering odometer reading that is smaller than previously recorded. Please confirm that correct vehicle is selected in the vehicle drop down");
            this.refs.odometerReadingRef.value = '';
            this.setState({
                odometerWarningShow: false,
            });
            return;

        }


        event.preventDefault();
        let json = JSON.stringify({
            "driverID": this.props.user.associateNum,
            "specialStopID": this.state.specialStopSelected,
            "specialStopIsRecurring": this.state.specialStopIsRecurring,
            "vehicle": this.state.overrideVehicle,
            "overrideOdometer": this.state.odometerCorrected
        })
        fetch("api/LmmiRoute/StartSpecialStopRoute",
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: json,
            })
            .then(response => response.json())
            .then(data => {

                if (data) {
                    this.setState({ routeLogRecord: data });


                } else {
                    this.setState({
                        confirmRouteError: "Failed to get route data"
                    })
                }
            })




    }
    updateFuelRecord = (id) => {
        var arr = this.state.fuelRecordID;
        arr.push(id);
        this.setState({ fuelRecordID: arr });
    }

    overrideVehicle = (e) => {
        if (e.target.value !== this.state.overrideVehicle) {
            this.setState({
                overrideVehicle: e.target.value
            });
            this.fetchVehicleStats(e.target.value);
        }
    }

    startRoute() {
        let json = JSON.stringify({
            "driverID": this.props.user.associateNum,
            "routeID": this.state.route.routeId,
            "vehicle": this.state.route.vehicle,
            "overrideVehicle": this.state.overrideVehicle,
            "overrideOdometer": this.state.odometerCorrected
        })
        fetch("api/LmmiRoute/StartRoute",
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: json,
            })
            .then(response => response.json())
            .then(data => {

                if (data) {
                    this.setState({ routeLogRecord: data });


                } else {
                    this.setState({
                        confirmRouteError: "Failed to get route data"
                    })
                }
            })



    }
    scrubData(val) {

        if (val === "" || val == null) {
            return "No Data";
        } else {
            if (val === 0.25) return "Quarter of a tank";
            if (val === 0.5) return "Half of a tank";
            if (val === 0.75) return "Three Quarters of a tank";
            if (val === 1) return "Full tank";
            return val;
        }
 
    }


    renderPreviousTripStats = (stats) => {
 
        return (
            <div>
             
                <p className="h4 text-center mg_green_font">
                    Previously Reported
                   </p>
                 
        <Table >
            <tbody>
                {(this.state.vehicleStats.fuel === "Propane")
                 ?<tr>
                    <th>Propane</th>
                     <th>{this.scrubData(stats.propaneTankLevel)}</th>
                            </tr> : <tr />}
               <tr>
                    <th>Gasoline</th>
                        <th>{this.scrubData(stats.gasolineTankLevel)}</th>
                </tr>
                <tr>
                    <th>Odometer</th>
                            <th>
                                {this.scrubData(stats.odometerReading)}
                                <br/>
                                <input type="text" id="odometerReading" placeholder="Override odometer value" ref="odometerReadingRef" onChange={(e) => this.handleOdometer(e)}/>
                            </th>
                </tr>
            </tbody>
                    </Table>
                    </div>);

    }

    renderRouteTable(route) {
        if (!route) {
            return (
                <div>
                    <p className="h4 text-center"> No routes or special stops found</ p>
                </div>
            );
        } else {

            //if there is no route but non assigned special stops, show special stops

            if (route.routeId == null && (route.specialStops != null && route.recurringStops!=null)) {

                 return this.renderSpecialAndRecurringStops(route);
            } else {

                 return this.renderSingleRoute(route);
            }
        }
    }


    handleRouteSelectionClick = (data, e) => {
      
        
        this.setState({
            specialStopSelected: data,
            specialStopIsRecurring:"N"

        });
        console.log( data);


    }



    handleRecurringRouteSelectionClick = (data, e) => {


        this.setState({
            specialStopSelected: data,
            specialStopIsRecurring:"Y"
        });
        console.log(data);


    }
    renderSpecialAndRecurringStops(route) {
        return (
            <div>
                <Table striped bordered hover >
                    <tbody>
                        <tr>
                            <th>Available Special Stops (touch to select)</th>
                            
                            </tr>
                        {
                            route.specialStops.map(specialStop =>

                                <tr key={specialStop.stoplogId} onClick={this.handleRouteSelectionClick.bind(this, specialStop.stoplogId)}
                                    style={
                                        this.state.specialStopSelected === specialStop.stoplogId
                                            ? { background: '#26d07c' }
                                            : { background: 'white' }
                                    }
                                    

                                >
                                    <td id={specialStop.stopLogId} >{specialStop.name} - {specialStop.type}
                                      
                                    </td>
                                </tr>
                            )
                        }
                            </tbody>
                            </Table>

                <Table striped bordered hover >
                    <tbody>
                        <tr>
                            <th>Recurring Stops (touch to select)</th>

                        </tr>
                        {
                            route.recurringStops.map(recurringStop =>

                                <tr key={recurringStop.stoplogId} onClick={this.handleRecurringRouteSelectionClick.bind(this, recurringStop.stoplogId)}
                                    style={
                                        this.state.specialStopSelected === recurringStop.stoplogId
                                            ? { background: '#26d07c' }
                                            : { background: 'white' }
                                    }


                                >
                                    <td id={recurringStop.stopLogId} >{recurringStop.stopDescription} - {recurringStop.city}

                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>






                    </div>
                );

    }


 



    renderSpecialStopList(specialStops) {
        if (specialStops) {            return (
                <tr>
                <th>
                        Special Stops ({specialStops.length}):
                 </th>    
                <td>
                    {specialStops.map(specialStop => <div key={specialStop.name}> <span className="glyphicon glyphicon-play" aria-hidden="true"></span>{specialStop.type}:{specialStop.name} ({specialStop.stopDatetime.substring(11, 16)})<br /> Material: {specialStop.lmmiMaterialLocation}<br />{specialStop.materialDesc}<br/></div>)}
                    </td>
                </tr>
            );
        } else {
            return "No Special Stops";
        }

    }

  
    renderSingleRoute(route)
    {
            if (!this.state.overrideVehicle) {
               this.setState({ overrideVehicle: this.state.route.vehicle });
            }
            let changeVehicleDropDown = this.renderVehicleDropDown(this.state.vehicles,'N');
            let specialStopList = this.renderSpecialStopList(this.state.route.specialStops);
     

            let renderPreviousTripStats = this.state.vehicleStats!==""?this.renderPreviousTripStats(this.state.vehicleStats):"";
            return (
                <div>
                < Table striped bordered hover >
                    <tbody>
                            <tr>
                                <th width="40%">Scheduled route</th>
                            <th>{route.routeId}</th>
                         </tr>
                            <tr>
                                <th>Assigned Vehicle</th>
                                <th> 
                                    {changeVehicleDropDown}
                                 
                                </th>
                            </tr>
                            <tr>
                                <th>Start Time</th>
                                <th>{route.startTime}</th>
                            </tr>

                            <tr>
                                <th>Return Time</th>
                                <th>{route.returnTime}</th>
                            </tr>
                    


                            {specialStopList}


                    </tbody>
                </Table >

                     

                    
            {renderPreviousTripStats}


                </div>

            );

        }
 

    
    renderVehicleDropDown = (vehicles, addEmpty) => {

   
        if (vehicles === "") {
            console.log("render nothing");
            return ("Loading....");
        } else {
            console.log("render form control with default");
            
            return (
                <FormControl as="select" componentClass="select" placeholder="select" value={this.state.overrideVehicle} onChange={this.overrideVehicle} >;
                        {addEmpty === 'Y' ? <option value='' key='empty'></option>:''}
                       {vehicles.map(vehicle => <option value={vehicle.vehicleNum}  key={vehicle.vehicleNum}>{vehicle.vehicleNum}</option>)}
                </FormControl>
            );
        }
}

renderFormSubmit (route)  {
        if (!route) return ("");

    if (this.state.routeLogRecord !== "") {

        console.log("vehicle stats is " + this.state.vehicleStats.fuel);
        return (
            <div>

                {<VehicleAddFuel routeLogID={this.state.routeLogRecord.logId} badgeCount={this.state.fuelRecordID.length} parentCallback={this.updateFuelRecord} fuel={this.state.vehicleStats.fuel} />}

                <Button
                    block
                    bsClass="mg_button_green center-block"
                    bsSize="large"
                    onClick={this.callbackToParent}
                >
                    Show me first stop
                    </Button>
            </div>
        );
    } else if (route.routeId != null) {
      return(
            <form onSubmit={this.handleSubmit}>
              <div className="mg_red_font text-center"> Did you confirm odometer reading?</div>

              <div className="mg_red_font text-center"> Did you confirm vehicle selection?</div>
                <div style={{ paddingTop: '25px' }} >
                    <Button
                        block
                        bsClass="mg_button_green center-block"
                        bsSize="large"
                        type="submit"
                    >
                        Confirm Route
                    </Button>
              </div>
                </form>
             
        );
    } else if (this.state.specialStopSelected!==""){
        let vehicleDropdown = this.renderVehicleDropDown(this.state.vehicles, 'Y');
        let renderPreviousTripStats = this.state.overrideVehicle===""?"":(this.state.vehicleStats !== "" ? this.renderPreviousTripStats(this.state.vehicleStats) : "");
        return (
            <Fragment>
                <p className="text-center mg_red_font strong">Please select vehicle to be used for this route </p>
                {vehicleDropdown}
                {renderPreviousTripStats}
                <form onSubmit={this.handleSubmit}>

                    <div style={{ paddingTop: '25px' }} >
                        <Button
                            block
                            bsClass="mg_button_green center-block"
                            bsSize="large"
                            onClick={this.startSpecialStopRoute}
                        >
                            Confirm Stop
                    </Button>
                    </div>


                </form>
             
                </Fragment>
            );
    }

     
    }
   
    callbackToParent = () => {
        this.props.parentCallback(this.state.routeLogRecord);
    }


    render() {
        let contents = this.state.loading
            ? <p><em>Loading</em></p>
            : this.renderRouteTable(this.state.route);

        let renderAction = this.state.loading
            ? ""
            : this.renderFormSubmit(this.state.route);

            /*
             *             <div>
                    {<VehicleAddIssue onClose={this.closeChild} />}
                </div>
                */

        return (
            <div>
                <p className="h3 text-center mg_blue_font">Welcome {this.props.user.nameFirst} </p>
                <p className="text-center"><em>Please drive safely and obey all traffic laws</em></p>



                {contents}
                {renderAction}


            </div>

        );
    }
}