import React, { Component,Fragment } from 'react';

import { Button, Checkbox, Panel } from "react-bootstrap";
 
 

export class CloseStop extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
            issues: [],
            selectedIssues: [],
            showIssues: false,
            issueNotes:"",
            quantityIssues: {},
            closeStopButtonDisabled: false,
            }

        this._isMounted = false;
        this.fetchIssuesTypes();

    }


    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        
    }

    fetchIssuesTypes() {
        fetch("api/LmmiRoute/GetIssueTypes",
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            })
            .then(response => response.json())
            .then(data => {
                    this.setState({ issues: data })
                    
                    });
                

    }
 
    resetButtonState=(e)=> {
        console.log("timeout fired");
        console.log(this._isMounted);
        if (this._isMounted) {
            this.setState({ closeStopButtonDisabled: false });
        }
    }

    closeThisStop = (event) => {
        console.log("closing stop");

        this.setState({
            closeStopButtonDisabled: true
        },
            () => {
                console.log("set timeout");
               this.props.parentCallback(this.state.selectedIssues, this.state.quantityIssues, this.state.issueNotes);
      
                setTimeout(() => this.resetButtonState(), 10000);
            }
        );

        

        
       
    }
        


    handleChange(i) {
        let arr = this.state.selectedIssues.slice();
        let val = i.target.id;
        let index = arr.indexOf(val);
        //is element already present in the array?
        if (index>-1) {
            //remove element, update array
            arr.splice(index, 0);

        } else {
            //add element, update array
             arr.push(i.target.id);

        }
        this.setState({ selectedIssues: arr });
    }

 


    handleChangeQuantity(i) {
        //we have already an array of selected checkboxes, this will be stored in a separate array of objects
        let val = i.target.value;
        let index = i.target.id;
        let map = this.state.quantityIssues;

        if (map.hasOwnProperty(index)) {
            delete map.key;
        } else {
            map[index] = val;
        }

        this.setState({ quantityIssues: map });
        
    }
    handleNotes = (event) => {
         this.setState({
            issueNotes: event.target.value,
        });
    }

 

    renderIssueQuantity(id) {
 
        return (
                <Fragment>
                <label htmlFor={id} className="mgLabelList"> How many? </label>
                <input type="text" id={id} onChange={(e) => this.handleChangeQuantity(e)} />
                </Fragment>
             );

    }
    renderIssueLineItem(issue) {
        let quantityField = (issue.hasQuantity) ? this.renderIssueQuantity(issue.typeId) : "";
        return (
            <div key={issue.typeId}>
            <Checkbox id={issue.typeId} key={issue.typeId} onClick={(e) => this.handleChange(e)}>{issue.shortTypeDesc}</Checkbox>
                {quantityField}
            </div>
        );

    }
    renderIssueLine(issue) {
        return (
            <Fragment key={issue.groupName} >
                <div className="mg_group_section h5">{issue.groupName}</div>
                {issue.issueTypes.map(element => this.renderIssueLineItem(element))}
           </Fragment>
        );
        
     }
    renderIssues(issues) {

        if (!this.state.showIssues) {
            return (
                <div>
                    <Button
                        block
                        bsClass="mg_button_block  center-block mg_red_font" onClick={() => { this.setState({ showIssues: true }); }}>
                        Report Stop Issues
                        </Button>
                     </div>
                );
        } else {

 

            return (
                <div>
                    <p className="h4 text-center mg_green_font">
                        Report Stop Issues
                   </p>

                    <Panel >
                        {issues.map(issue => this.renderIssueLine(issue))}
                    </Panel>

                    <label className="mgLabel ">Notes:</label><input id="issueNotes" type="textarea" rows="5" onChange={(e) => this.handleNotes(e)} />
                  
                    <br />
                    <br/>
                </div>
            );
        }
    }
    

    render() {

        let issuesContent = this.props.stopType === "Regular" ? this.renderIssues(this.state.issues) : "";
        let label = this.props.stopType === "Regular" ? "Go to next stop" : "Continue";      
        
        return (
            <div>
       
                {issuesContent}
                <Button
                    bsSize="large"
                    bsClass="mg_button_green center-block"
                    onClick={this.closeThisStop}
                    disabled={this.state.closeStopButtonDisabled}>
                    {this.state.closeStopButtonDisabled ? (<span className="glyphicon glyphicon-refresh spinning"></span>): <span/>}
                    {label}
                 
                 </Button>
                
            </div>

        );
    }
}