import React, { Component } from 'react';

import { Button,  Form } from "react-bootstrap";
import {QRScanner} from './QRScanner'
 
 

export class CheckIn extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
                
                selectedReason:"",
                showMe: false
            }



    }




    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.selectedReason === "") {
            alert("Please select the reason for using manual check in");
            return;
        }
        this.checkOut();
        this.sendCallback("manual", this.state.selectedReason);

    }

    handleSpecialStopCheckin = (event) => {
        event.preventDefault();
        this.checkOut();
        this.sendCallback("specialStop", this.props.stopType);

    }
    handleRecurringCheckIn = (event) => {
        event.preventDefault();
        this.checkOut();
        this.sendCallback("recurringStop", this.props.stopType);

    }

    handleChange=e=> {

        this.setState({
            selectedReason: e.target.value
        });

    }
    checkIn = () => {
        this.setState({
            showMe: true
        })
    }
    checkOut = () => {
        this.setState({
            showMe: false
        })
    }
    sendCallback = (data, data1) => {
        this.props.parentCallback(data, data1);
    }
    processAutoScan=(result, error)=> {
        if (result != null) {
            this.props.parentCallback("auto", result);
        } else if (error != null) {
            alert("Scan error, please use manual check in option");
        }
    }
    render() {
        if (!this.state.showMe) {
            return (
                <div>
                    <p ><Button block
                        bsClass="large mg_button_green center-block" onClick={this.checkIn}>
                        Check-In Arrival
                        </Button>
                    </p>
                </div>
            );
        } else {

            if (this.props.stopType === "Recurring") {
                return (
                    <div>
                        <Button className="center-block mg_button"
                            onClick={this.checkOut}>Cancel Check-In</Button>

                        <Button
                            type="submit"
                            className="center-block mg_button" variant="primary" onClick={this.handleRecurringCheckIn}>
                            Recurring Stop Completed
                 </Button>
                    </div>
                );
            }


            if (this.props.stopType !== "Regular") {
                return (
                    <div>
                    <Button className="center-block mg_button"
                        onClick={this.checkOut}>Cancel Check-In</Button>

                    <Button
                         type="submit"
                        className="center-block mg_button" variant="primary" onClick={this.handleSpecialStopCheckin}>
                       Close Special Stop
                 </Button>
                    </div>
                    );
            } else {
                return (
                    <div>
                        <Button className="center-block mg_button"
                            onClick={this.checkOut}>Cancel Check-In</Button>


                        <QRScanner parentCallback={this.processAutoScan} />
                        <br />
                        <br />
                        <p> <em>If placard not available, please do manual check-in after selecting the reason</em> </p>
                        <Form onSubmit={this.handleSubmit}>

                            <div className="formCheck">

                                <input
                                    type="radio"
                                    id="custDoorClosed"
                                    value="custDoorClosed"
                                    checked={this.state.selectedReason === "custDoorClosed"}
                                    onChange={this.handleChange}

                                />
                                <label htmlFor="custDoorClosed" className="mgRadioLabel">
                                   No placard to scan
                            </label>

                                <br />

                                <input
                                    type="radio"
                                    id="badBarcode"
                                    value="badBarcode"
                                    className="form-check-input"
                                    checked={this.state.selectedReason === "badBarcode"}
                                    onChange={this.handleChange}
                                />
                                <label htmlFor="badBarcode" className="mgRadioLabel">
                                    No QR Code on placard
                            </label>
                                <br />

                                <input
                                    type="radio"
                                    id="other"
                                    value="other"
                                    className="form-check-input"
                                    checked={this.state.selectedReason === "other"}
                                    onChange={this.handleChange}
                                />
                                <label htmlFor="other" className="mgRadioLabel">
                                    Other
                            </label>

                                <br />
                                <Button

                                    type="submit"
                                    className="center-block mg_button" variant="primary">
                                    Manual Check-In
           
                 </Button>
                            </div>
                        </Form>

                    </div>

                );
            }
        }
    }
}