import React, { Component } from 'react';

import { Button, Modal, Grid, Row, Col, Panel } from "react-bootstrap";

import { CheckIn } from './CheckIn.jsx';
import { CloseStop } from './CloseStop.jsx';

export class RouteStop extends Component {

    constructor(props) {
        super(props);
        console.log("in constructor, super props were just called and stop id is " + this.props.stopId);
        this.state =
       {
            routeLogID: this.props.route.logId,
             loading: true,
             stop: "",
             showRoutes: false,
            stops: [],
            renderCheckin: false,
            checkInMode: "",
            checkInData: "",
            stopId: this.props.stopId,
            checkInTime:""
        }


     
    }

  
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        console.log("in component did update, stop id is  " + this.props.stopId + " and prev is " + prevProps.stopId);
        if (this.props.stopId !== prevProps.stopId) {
            this.setState({ stopId: this.props.stopId });
            this.fetchDataForStop(this.props.stopId);

        }
    }


    componentDidMount() {
        console.log("componentDidMount " + this.state.stopId);
        this.fetchDataForStop(this.state.stopId);
        
    }

    fetchDataForStop = (stopID) => {
        console.log("calling fetch data for stop, stop time is  " + stopID);

        fetch(`api/LmmiRoute/GetNextStop?routeID=${this.props.route.routeId}&specialStopID=${this.props.route.stoplogId}&recurringStopID=${this.props.route.recurringStopId}&pickupTime=${stopID}&driverID=${this.props.user.associateNum}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                })
            })
            .then(response => response.json())
            .then(data => {
                if (data.remainingStops < 0) {

                    this.props.parentCallback(null);
                } else {

                    this.setState({
                        stop: data,
                        loading: false,
                        renderCheckin: false,
                        checkInMode: "",
                        checkInData: "",
                        checkInTime:""


                    });
                }
            });

    }
    checkInCallback = (checkInOption, additionalCheckInData) => {
        this.setState({ checkInMode: checkInOption, checkInData: additionalCheckInData,checkInTime:new Date() })
    }

    closeStopCallback = (data, quantity, notes) => {
        console.log("closeStopCallback");
        let json = JSON.stringify({
            "selectedCloseMethod": this.state.checkInMode,
            "checkInData": this.state.checkInData,
            "checkInTime":this.state.checkInTime,
            "issueList": data,
            "quantityIssue":quantity,
            "issueNotes":notes,
            "routeLogID": this.state.routeLogID,
            "currentPickupTime": this.state.stop.stopData.pickup_Time,
            "pickupID": this.state.stop.stopData.stoplog_id,
            "acct_num": this.state.stop.accountNum,
            "custID": this.state.stop.stopData.cust_id
           })

        console.log(json);
        console.log("posting closeStop");
        fetch("api/LmmiRoute/CloseStop",
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: json,
            })
            //.then (response=>console.log(response))
            .then(response => response.json())
            .then(data => {
                this.props.parentCallback(this.state.stop);
            })
            .catch(err=>console.log(err));
    }


    
    handleModalClose = (event) => {
        this.setState({ showRoutes: false });
    
    }
    handleModalOpen = (event) => {
        this.setState({ showRoutes: true });

    }

    loadDirections = () => {
        //fetch and do URL encode on address
        let addressBit = this.state.stop.stopData.addr1 + ' ' + this.state.stop.stopData.city + ' ' + this.state.stop.stopData.state + ' ' + this.state.stop.stopData.zip;
        var addressBitEncoded = encodeURIComponent(addressBit);
        //if needed save state here
        //now open the native app

        window.open("maps://maps.apple.com/?daddr=" + addressBitEncoded);

    }

    renderRouteList() {
        if (this.state.stops.length === 0) {

            fetch(`api/LmmiRoute/GetAllStops?routeID=${this.props.route.routeId}&driverID=${this.props.user.associateNum}`,
                {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                    })
                })
                .then(response => response.json())
                .then(data => {
                    this.setState({ stops: data });
                    this.renderRouteList();
                });
        } else {

            return (
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Pickup Time</th>
                       </tr>
                    </thead>
                    <tbody>
                        {this.state.stops.map(stop =>
                            <tr key={stop.name}>
                                <td>
                                   {(stop.stop_Type === 'Regular') ? '' : <span className="glyphicon glyphicon-play" aria-hidden="true"></span>}
                                    {stop.name}
                               
                                </td>
                                <td>{stop.addr1}</td>
                                <td>{stop.pickup_Time}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        }
    }
    renderCloseOptions = () => {
        if (this.state.checkInMode.length === 0) {
            return (
                <div>
                    <CheckIn stopType={this.state.stop.stopData.stop_Type} parentCallback={this.checkInCallback} />
               
                    </div>
                );
        } else {
            return (
                <CloseStop stopType={this.state.stop.stopData.stop_Type} parentCallback={this.closeStopCallback} />

                );

        }
    }


    renderModalForRoutes = () => {
        let routeList = this.renderRouteList();
        return (
            <Modal show={this.state.showRoutes} onHide={this.handleModalClose}>
                <Modal.Header>
                    <p className="mg_blue_font h3"> Today's stops for {this.props.route.routeId}</p>
                    <p className="mg_blue_font h5"> Special stops marked with  <span className="glyphicon glyphicon-play" aria-hidden="true" /></p>
                    <button type="button" className="close" onClick={this.handleModalClose}>CLOSE</button>
                </Modal.Header>
                <Modal.Body>

                    {routeList}
                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>
        );

    }
    formatIfPhone(textValue) {
        if (textValue == null) {
            return "";
        }
        console.log ("in formatIfPhone with "+textValue)
        let phoneRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g
        

        let matchArray = textValue.match(phoneRegex);

        if (matchArray != null) {
        let returnArray = [];
        let lastIndex = 0;
            for (let i = 0; i < matchArray.length; i++) {

                //substring prior
                let subs = textValue.substring(lastIndex, textValue.indexOf(matchArray[i]) );
                console.log(subs);
                returnArray.push(subs);
                //is there an extension? because it should have comma afterwards
                               

                returnArray.push(<a key={matchArray[i]} href={`tel:${matchArray[i].replace('ext.', ',').replace(/\s+/g, '')}`}>{matchArray[i]}</a>);
                //update last array
                lastIndex = textValue.indexOf(matchArray[i])+  matchArray[i].length;

            }
            //is there a last piece left to push?
            if (lastIndex < textValue.length) {
                returnArray.push(textValue.substring(lastIndex, textValue.length));
            }
            console.log(returnArray);
            return returnArray;
        } else {
            return textValue;
        }

  
    }
 
    renderStopComponent(stopData) {
       
        if (!stopData) {
            return (
                <div>
                    <p className="h4 text-center"> Cannot load next stop</p>
                </div>
            );
        } else {
            let renderOptions = this.renderCloseOptions();
            let renderModalListOfAllStops = (this.props.route.routeId == null) ?"":this.renderModalForRoutes();
   
            return (
                <div>
                    
                    <p className="h3 text-center mg_blue_font">Next stop: {stopData.stopData.pickup_Time} </p>

                    {renderOptions}
                    <div>
                    <Panel>
                    <Grid  >
                                <Row>
                                    
                                    <p className="mg_blue_font h4 text-center">{stopData.stopData.name}</p></Row>
                                {stopData.accountNum !== null ? <p className="mg_blue_font h6 text-center"> Acct # {stopData.accountNum} </p> : ""}

                                {(stopData.stopData.stop_Type !== 'Regular') ? <p className="mg_blue_font h5 text-center">Special Stop - {stopData.stopData.stop_Type}</p>:""}
                   

                          <Row className="show-grid">
                            <Col xs={6} md={8}>
                     
                                <p > {stopData.stopData.addr1} </p>
                                        <p > {stopData.stopData.city} {stopData.stopData.state} {stopData.stopData.zip} </p>
                              
                                

                            </Col>
                            <Col xs={6} md={8}>
                               
                           
                                        <Button className="mgButton" onClick={this.loadDirections}>
                                           Directions
                                         </Button>
                            </Col>
                        </Row>
                        </Grid>
                        </Panel>
              
                        {(stopData.stopData.stop_Type !== 'Regular') ?
                            <p><b>Contact:</b>
                                {stopData.stopData.contact==null ? "" : stopData.stopData.contact}
                                {
                                    (stopData.stopData.extension==null || stopData.stopData.extension.length === 0) ?
                                        <a href={`tel:${stopData.stopData.phone}`}> {stopData.stopData.phone}</a> :
                                        <a href={`tel:${stopData.stopData.phone},${stopData.stopData.extension}`}> {stopData.stopData.phone} ext.{stopData.stopData.extension} </a>
                                }
                            </p> : ""}
                         
                           <p> <b>Notes:</b> 
                                <br/> { this.formatIfPhone(stopData.stopData.notes1) }  
                                <br/> { this.formatIfPhone(stopData.stopData.notes2) }
                            </p>
                           

                        {this.props.route.routeId == null ? "" :
                            <Grid>
                                <Row>
                                    <Col xs={6} md={8}>
                                        <p className="h4 mg_blue_font"> Stops Left:  {stopData.remainingStops === 0 ? "Last stop" : stopData.remainingStops}</p>
                                    </Col>
                                    <Col xs={6} md={8}>

                                        <Button className="mgButton" onClick={this.handleModalOpen}>
                                            View All Route Stops
                                        </Button>
                                    </Col>
                                </Row>
                            </Grid>
                        }

</div>
                    {renderModalListOfAllStops}                


           




 

            </div>
             
            );
        }
    }
    render() {
         let contents = this.state.loading
            ? <p><em>Loading</em></p>
            : this.renderStopComponent(this.state.stop);

        return (
            <div>
                {contents}
            </div>

        );
    }
}