import React, { Component } from 'react';
import { ConfirmRoute } from './ConfirmRoute';
import { RouteStop } from './RouteStop';
import { CloseRoute } from './CloseRoute';
import Header from './Header.jsx'
import Login from './Login.jsx'
import { Button } from "react-bootstrap";

export class Home extends Component {

    
    constructor(props) {
        super(props);
        this.state =
            {
            isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')) || false,
            userData: JSON.parse(localStorage.getItem('userData')) ||"",
            route: JSON.parse(localStorage.getItem('route')) || "",
            stopId: localStorage.getItem('stopId') || "00:00:00",
            routeWasObtained: JSON.parse(localStorage.getItem('routeWasObtained')) ||false,
            routeIsDone: JSON.parse(localStorage.getItem('routeIsDone')) || false,
            initialFuelingDone: JSON.parse(localStorage.getItem('initialFuelingDone')) ||false,
            routeLogId: JSON.parse(localStorage.getItem('routeLogId')) || "",
   
            }

        //window.location.reload(true);
    }
    

    authenticateUserCallback = (data) => {
        //only called when user is authenticated
 
        this.setState({ isAuthenticated: true, userData: data });

        this.setState({
            isAuthenticated: true, userData: data
        }, () => {
                localStorage.setItem('isAuthenticated', JSON.stringify(true));
                localStorage.setItem('userData', JSON.stringify(data));
            });

         
    }

    confirmRouteCallback = (routeStop) => {
        //save route stop log data in route object
        this.setState({ route: routeStop, routeWasObtained: true }
            , () => {
                localStorage.setItem('route', JSON.stringify(routeStop));
                localStorage.setItem('routeWasObtained', JSON.stringify(true));
            }
        );
    }

    stopIsDoneCallback = (stopData) => {
        if (stopData === null || stopData === undefined || stopData.remainingStops < 0 || this.state.route.recurringStopId!=null) {
            //all stops were completed
            this.setState({
                routeIsDone: true,
                stopId: "00:00:00"}
                , () => {
                    localStorage.setItem('routeIsDone', JSON.stringify(true));
                    localStorage.setItem('stopId', "00:00:00");
                    
                });
        } else {
            //refresh child again
            console.log("pickup time was " + stopData.stopData.pickup_Time);
            this.setState({ stopId: stopData.stopData.pickup_Time }
                , () => {
                    localStorage.setItem('stopId', stopData.stopData.pickup_Time);
                })
        }
    }

   

    logoffAfterPrompt = () => {
        if (window.confirm('Are you sure you wish to log off and abandon the route?')) {
            this.logoffCallback();
        }
    }
    logoffCallback = (id) => {
        this.setState({
            isAuthenticated: false,
            userData: "",
            route: "",
            routeWasObtained: false,
            initialFuelingDone: false,
            routeIsDone: false,
            stopId: "00:00:00"

        }, () => {
                localStorage.setItem('isAuthenticated', JSON.stringify(false));
                localStorage.setItem('userData', JSON.stringify(""));
                localStorage.setItem('route', JSON.stringify(""));
                localStorage.setItem('stopId', "00:00:00");
                localStorage.setItem('routeWasObtained', JSON.stringify(false));
                localStorage.setItem('initialFuelingDone', JSON.stringify(false));
                localStorage.setItem('routeIsDone', JSON.stringify(false));
        });
    }

    renderLogoff() {
        return (
            <div>
                <br />
                <br />
                <Button
                    block
                    bsClass="large mg_button center-block"
                    type="submit"

                    onClick={() => this.logoffAfterPrompt()}
                >
                    Log Off
                    </Button>
                <br />
                <br />
            </div>);
    }


    getLoginElement() {
        return (
            <Login parentCallback={this.authenticateUserCallback}/>
            )
    }

    
    getRouteConfirmation() {
        let logoff = this.renderLogoff();
        return (
            <div>
                <ConfirmRoute user={this.state.userData} parentCallback={this.confirmRouteCallback} noRouteParentCallback={this.logoffCallback} />
                {logoff}
                </div>
        );
    }

    getRouteStop() {
        console.log("passing new props to route stop and time will be " + this.state.stopId);
        let logoff = this.renderLogoff();
        return (
            <div>
                <RouteStop route={this.state.route} stopId={this.state.stopId} user={this.state.userData} parentCallback={this.stopIsDoneCallback} />
                {logoff}
                </div>
        );

    }

  
    getRouteClose() {

        return (
            <CloseRoute routeLogId={this.state.route.logId} initialOdometer={this.state.route.odometer} parentCallback={this.logoffCallback} />
        );

    }
  



 

    render() {
        let innerContent;
        console.log("in render for home");
        console.log("stopID is " + this.state.stopId);
 

        if (!this.state.isAuthenticated) {
            console.log("render login");
            innerContent = this.getLoginElement();
        } else {


            //modes are: we have no route; we have route but it's not closed, route is closed
            if (!this.state.routeWasObtained) {
                innerContent = this.getRouteConfirmation();
            
            } else {
                if (!this.state.routeIsDone) {
                    console.log("rerender route stop");
                    innerContent = this.getRouteStop();
                } else {
                    innerContent = this.getRouteClose();
                }
            }

     
        }

        return (
                <div>
                    <Header />
                    {innerContent}
                    <br />
                    <br />
                  </div>

            );
        
  }
}
